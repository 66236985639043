import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { isDev } from 'src/utils/env';
import Cookies from 'js-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import { APP_URL, API_URL } from 'src/consts/cookies';
import { parseBooleanFromString } from 'src/utils/boolean';
import { setMuiLicense, ThemeProvider, timelogTheme } from '@timelog/ui-library';
import App from './App';
import './services/datadogLogs';
import './services/realUserMonitoring';
import './services/i18n';
import reportWebVitals from './reportWebVitals';
import worker from './mocks/browser';
import './assets/styles/globals.scss';

const BYPASS_TLP = parseBooleanFromString(process.env.REACT_APP_BYPASS_TLP);
const ENABLE_MOCK = parseBooleanFromString(process.env.REACT_APP_ENABLE_MOCK);

if (isDev && BYPASS_TLP) {
  Cookies.set(APP_URL, 'http://localhost/tlp');
  Cookies.set(API_URL, 'http://mockapi');

  worker.start({ onUnhandledRequest: 'bypass' });
}

if (isDev && ENABLE_MOCK) {
  worker.start({ onUnhandledRequest: 'bypass' });
}

setMuiLicense(process.env.REACT_APP_MUI_PRO_LICENSE_KEY ?? '');

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <Router>
      <ThemeProvider theme={timelogTheme}>
        <App />
      </ThemeProvider>
    </Router>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
